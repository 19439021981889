import React, { Component } from 'react';


class Resume extends Component {
  render() {
    return (
      <div className="resume">
        <iframe src="https://drive.google.com/file/d/16WxB_uDV0ptkLUpXLJSZOUUMNsIXrInv/preview" className="responsive-iframe"></iframe>
      </div>
    );
  }
}

export default Resume;