import React from "react";

const CssLoader = () => {
  return (
    <div className="page-wrapper">
        <div className="bubble"></div>
    </div>
  );
};

export default CssLoader;